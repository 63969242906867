export const WORDS = [
  'Crazy',
  'Click',
  'Three',
  'Sober',
  'Drama',
  'Album',
  'Music',
  'Legos',
  'Party',
  'Vegas',
  'Crazy',
  'Dance',
  'Stage',
  'Balls',
  'World',
  'Award',
  'Clock',
  'Crowd',
  'Final',
  'Radio',
  'Smile',
  'Night',
  'Karma',
  'Grass',
  'House',
  'Trick',
  'Vinyl',
  'Socks',
  'Weird',
  'Games',
  'Taxes',
  'Tours',
  'Heart',
  'Slays',
  'Sheep',
  'Merch',
  'Ready',
  'Drums',
  'Ankle',
  'Beats',
  'Remix',
  'Happy',
  'Brain',
  'Green',
  'Break'

]
